<template>
  <v-dialog max-width="800" v-model="value" @click:outside="close">
    <v-card class="pa-5">
      <v-card-title class="justify-center">
        <h2>
          <span class="orange--text">{{ title }}</span>
        </h2>
      </v-card-title>
      <v-card-text>
        <v-form>
          <v-text-field v-model="employee.name" label="Полное имя" required>
          </v-text-field>
          <v-text-field v-model="employee.email" label="Емаил" required>
          </v-text-field>
          <v-text-field
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPassword ? 'text' : 'password'"
            @click:append="showPassword = !showPassword"
            v-model="employee.password"
            label="Пароль"
          >
          </v-text-field>

          <v-select
            v-model="employee.role"
            :items="roles"
            item-text="name"
            item-value="value"
            persistent-hint
            single-line
          ></v-select>
          <v-row align="center">
            <v-col cols="4">
              <v-checkbox
                label="Генерировать QR"
                v-model="employee.accountSetting.canGenerateQR"
                color="orange"
              ></v-checkbox>
            </v-col>
            <v-col cols="4">
              <v-checkbox
                label="Получать отчёты"
                v-model="employee.accountSetting.canGetReport"
                color="orange"
              ></v-checkbox>
            </v-col>
            <v-col cols="4">
              <v-checkbox
                label="Отправлять SMS-сообщения"
                color="orange"
                v-model="employee.accountSetting.canSendSMS"
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-row justify="space-between" align="center">
            <v-col cols="4" class="my-3 pt-4">
              <v-checkbox
                v-model="addAirport"
                label="Привязать к порту"
                color="orange"
                class="mt-0"
              >
              </v-checkbox>
            </v-col>
            <v-col cols="8">
              <v-select
                v-if="addAirport"
                v-model="employee.accountSetting.airportId"
                :items="airports"
                item-text="name"
                item-value="id"
                label="Выберите аэропорт"
              />
            </v-col>
          </v-row>
          <v-row justify="space-between" align="center">
            <v-col cols="4" class="my-3 pt-4">
              <v-checkbox
                v-model="addCompany"
                label="Привязать к компании"
                color="orange"
                class="mt-0"
              >
              </v-checkbox>
            </v-col>
            <v-col cols="8">
              <v-select
                v-if="addCompany"
                @click="getCompanies"
                v-model="employee.accountSetting.bpoCompanyId"
                :items="companies"
                item-text="name"
                item-value="id"
                label="Выберите компанию"
              />
            </v-col>
          </v-row>
          <v-row justify="space-between" align="center">
            <v-col cols="4" class="my-3 pt-4">
              <v-checkbox
                v-model="addKkt"
                label="Привязать к Ккт"
                color="orange"
                class="mt-0"
              >
              </v-checkbox>
            </v-col>
            <v-col cols="8">
              <v-text-field 
                v-if="addKkt"
                v-model="regIdInput" 
                label="Reg Id (16 цифр: пример - 0006470395055076)" required>
              </v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn color="error" @click="$emit('closeModal')"> Отмена </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="success" @click="save"> Сохранить </v-btn>
      </v-card-actions>
    </v-card>
    <error-modal v-model="error.show" :error="error.value"></error-modal>
  </v-dialog>
</template>

<script>
import ErrorModal from "@/components/UI/ErrorModal.vue";
import { id } from "date-fns/esm/locale";
export default {
  name: "EditEmployee",
  components: {
    ErrorModal,
  },
  props: {
    title: {
      type: String,
      default: "Создание пользователя",
    },
    value: {
      type: Boolean,
      default: false,
    },
    user: {
      type: Object,
      default: function() {
        return {
          name: "",
          email: "",
          password: "",
          role: 1,
          accountSetting: {
            airportId: null,
            canGenerateQR: false,
            canGetReport: false,
            canSendSMS: false,
            bpoCompanyId: null,
            kktId: null,
          },
        };
      },
    },
    isCreating: {
      type: Boolean,
      default: false,
    },
    regIdInput: {
      type: String,
      default: "",
    },
  },
  data: function() {
    return {
      employee: {
        name: "",
        email: "",
        password: "",
        role: 1,
        accountSetting: {
          airportId: null,
          canGenerateQR: false,
          canGetReport: false,
          canSendSMS: false,
          bpoCompanyId: null,
          kktId: null,
        },
      },
      roles: [
        { name: "Администратор", value: 0 },
        { name: "Кассир", value: 1 },
        { name: "Технолог", value: 5 },
        { name: "СубАгент", value: 3 },
        { name: "Только чтение", value: 4 },
      ],
      airports: [
        { name: "Шереметьево", id: 2009 },
        { name: "Домодедово", id: 2010 },
        { name: "Пулково", id: 1707 },
      ],
      companies: [],
      kkt: {
        regId: "",
        storageId: "",
        sbisCompanyId: null,
        status: null,
      },
      showPassword: false,
      error: {
        value: {
          Code: "",
          Message: "",
        },
        show: false,
      },
      addAirport: false,
      addCompany: false,
      addKkt: false,
    };
  },
  mounted() {
    this.employee = {
      id: this.user.id,
      name: this.user.name,
      email: this.user.email,
      role: this.user.role,
      password: this.user.password,
      accountSetting: this.user.accountSetting,
    };
    this.addAirport = !!this.user.accountSetting.airportId;
    this.addCompany = !!this.user.accountSetting.bpoCompanyId;
    this.addKkt = !!this.user.accountSetting.kktId;
    this.regIdInput = this.user.accountSetting.kkt.regId;

    this.getCompanies();
  },
  methods: {
    save() {
      this.$store.dispatch({
        type: "setActions",
        accept: this.isCreating
          ? () => {
              this.CreateUser(this.employee);
            }
          : () => {
              this.UpdateUser(this.employee);
            },
      });
      this.$store.commit("setMessage", "что хотите сохранить");
      this.$store.commit("openDialog", true);
    },
    async CreateUser(user) {
      await this.registration(user);
      this.updateData(user);
      this.$emit("saveUser", user);
      this.$emit("closeModal");
    },
    async UpdateUser(user) {
      await this.edit(user);
      this.updateData(user);
      this.$emit("edit", user);
      this.close();
    },
    updateData(user){
      let airport = this.findObjectById(this.airports, user.accountSetting.airportId);
      let company = this.findObjectById(this.companies, user.accountSetting.bpoCompanyId);

      user.accountSetting.airport = !!airport
        ? { nameRu: airport.name, id: airport.value }
        : null;
      user.accountSetting.bpoCompany = !!company
        ? { name: company.name, id: company.id }
        : null;
      user.accountSetting.kkt = !!kkt
        ? { regId: kkt.regId, id: kkt.id, storageId: kkt.storageId, status: kkt.status }
        : null;      
      
    },
    findObjectById(array, id) {
      return array.find((e) => e.id === id);
    },
    findObjectByRegId(array, id) {
      return array.find((e) => e.regId === id);
    },

    close() {
      console.log("Закрытие");
      this.$emit("closeModal");
    },
    async edit(employee) {
      if (!this.addAirport) employee.accountSetting.airportId = 0;
      if (!this.addCompany) employee.accountSetting.bpoCompanyId = 0;
      if (!this.addKkt) employee.accountSetting.kktId = 0;
      else 
      { 
        try{
          var kkt = await this.getKkt(this.regIdInput);
          employee.accountSetting.kkt = kkt;
          employee.accountSetting.kktId = kkt.id;
        } catch(e){
          console.log("edit - Error getKkt method");
          this.error.show = true;
          this.error.value.Code = e.response.status;
          this.error.value.Message = e.response.data.Message;
        throw e;
        }
      }
      console.log("edit" + this.addKkt + " " +  employee.accountSetting.kktId);
      try {
        console.log(employee)
        let resp = await this.$axios.post(
          this.$enums.Endpoints.Account.Edit,
          employee
        );
        return resp.data;
      } catch (e) {
        console.log("edit - Error");
        this.error.show = true;
        this.error.value.Code = e.response.status;
        this.error.value.Message = e.response.data.Message;
        throw e;
      }
    },
    async registration(employee) {
      try {
        let resp = await this.$axios.post(
          this.$enums.Endpoints.Account.Create,
          employee
        );
        return resp.data;
      } catch (e) {
        console.log("registration - Error");
        this.error.show = true;
        this.error.value.Code = e.response.status;
        this.error.value.Message = e.response.data.Message;
        throw e;
      }
    },
    async getCompanies() {
      try {
        let resp = await this.$axios.get(this.$enums.Endpoints.Data.Companies);
        this.$set(this, "companies", resp.data);
      } catch (e) {
        this.error.show = true;
        this.error.value.Code = e.response.status;
        this.error.value.Message = e.response.data.Message;
        throw e;
      }
    },

    async getKkt(regIdInput) {
      try {
        console.log("getKkt -" + regIdInput);
        let resp = await this.$axios.get(`${this.$enums.Endpoints.Other.Kkt}/${regIdInput}`);
        this.$set(this, "kkts", resp.data);
        return resp.data;

      } catch (e) {
        this.error.show = true;
        this.error.value.Code = e.response.status;
        this.error.value.Message = e.response.data.Message;
        throw e;
      }
    },
  },
};
</script>
