<template>
  <v-container fluid>
    <edit-employee
      @closeModal="closeModall"
      @saveUser="saveUser"
      v-model="modalpopup"
      title="Добавление нового пользователя"
      isCreating
    ></edit-employee>
    <v-container class="filters">
      <v-row justify="start" class="mb-1">
        <v-col cols="12" md="3">
          <v-text-field
            label="Фамилия или емаил"
            v-model="fieldForFind"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="3">
          <v-combobox label="Роль" :items="roles" v-model="selectedRole">
          </v-combobox>
        </v-col>
        <v-col outlined tile>
          <v-btn
            block
            dark
            color="orange"
            elevation="0"
            v-on:click="loadEmployees"
          >
            Поиск
          </v-btn>
        </v-col>
        <v-col
          outlined
          tile
          v-if="this.$store.getters.user.role === this.$enums.Roles.Admin"
        >
          <v-btn
            v-on:click="modalpopup = true"
            block
            dark
            color="green"
            evelation="0"
          >
            Создать
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <v-row v-if="loading" no-gutters>
      <v-col>
        <overlay-loader :loading="loading"></overlay-loader>
      </v-col>
    </v-row>

    <div v-else-if="Employees.length" class="mt-sm-3">
      <v-row v-for="(emp, i) in sortedEmployees" :key="emp.id">
        <employee-item :user="emp" :number="i" @deleteFromList="deleteFromList">
        </employee-item>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import EmployeeItem from "@/components/EmployeePage/EmployeeItem.vue";
import OverlayLoader from "@/components/UI/OverlayLoader.vue";
import EditEmployee from "@/components/EmployeePage/EditEmployee.vue";
import ApiCalls from "@/mixins/booking/ApiCalls";
import DateFormat from "@/mixins/DateFormat";
import { ca } from "date-fns/locale";

export default {
  name: "EmployeesList",
  components: { OverlayLoader, EmployeeItem, EditEmployee },
  mixins: [ApiCalls, DateFormat],
  data() {
    return {
      page: 1,
      pageCount: 1,
      perPage: 10,
      loading: true,
      Employees: [],
      fieldForFind: "",
      email: "",
      modalpopup: false,
      selectedRole: "Кассир",
      roles: ["Кассир", "Администратор", "СубАгент", "Только чтение", "Технолог"],
    };
  },
  async mounted() {
    await this.loadEmployees();
  },
  methods: {
    async loadEmployees() {
      this.loading = true;
      this.Employees = await this.getEmployees(
        this.fieldForFind,
        this.getSelectedRole
      );
      this.loading = false;
    },
    closeModall() {
      this.modalpopup = false;
    },
    saveUser(user) {
      this.Employees.push(user);
    },
    deleteFromList(val) {
      this.Employees = this.Employees.filter(function (e) {
        return e != val;
      });
    },
    async getEmployees(fieldForFind = "", role = 1) {
      try {
        let resp = await this.$axios.get(
          this.$enums.Endpoints.Account.Get + `?text=${fieldForFind}&role=${role}`
        );
        return resp.data;
      } catch (e) {
        console.log("getEmployees - Error");
        this.$store.state.error = {
          show: true,
          value: {
            Code: e.response.status,
            Message: e.response.data.Message,
          },
        };
        return [];
      }
    },
  },
  computed: {
    getSelectedRole: function () {
      switch(this.selectedRole){
        case "Администратор": return 0;
        case "Кассир": return 1;
        case "СубАгент": return 3;
        case "Только чтение": return 4;
        case "Технолог": return 5;
      }
    },
    sortedEmployees: function () {
      return this.Employees.sort((a, b) => {
        return a.name > b.name ? 1 : -1;
      });
    },
  },
};
</script>
